import React, { useState, useEffect, useRef, useCallback } from "react";
import env from "@beam-australia/react-env";
import { useForm, Controller } from "react-hook-form";

import useApi from "../../../hooks/useApi";

import { BlockUI } from "primereact/blockui";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";

import { useTranslation } from "react-i18next";

import { useDebounce } from "../../../hooks/useDebounce";
import useCRUDService from "../../../hooks/useCRUDService";

import Searcher from "../../../components/searcher/searcher";
import Layout from "../../../layout/layout";
import DateTimeHelper from "../../../utils/dateTimeHelper";
import VoucherDetail from "./vouchersDetail";
import "./vouchers.css";
import Header from "../../../layout/header";
import VoucherForm from "./vouchersForm";

import useSkeleton from "../../../hooks/useSkeleton";

const VouchersActive = () => {
  const s = useSkeleton("commons");
  const api = useApi();

  const { t } = useTranslation();
  const CRUDService = useCRUDService("vouchers");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [entities, setEntities] = useState(null);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const items = [
    { name: "Activos", value: "VOUCHERS_ACTIVE" },
    { name: "Finalizados", value: "VOUCHERS_TRIP_FINISHED" },
    { name: "Cancelados", value: "VOUCHERS_CANCELED" },
  ];

  const [currentVoucherStatusFilter, setCurrentVoucherStatusFilter] = useState(
    items[0].value
  );

  const [detailDialog, setDetailDialog] = useState(false);
  const [formDialog, setFormDialog] = useState(false);
  const [finalWeightDialog, setFinalWeightDialog] = useState(false);
  const [currentVoucherId, setCurrentVoucherId] = useState(-1);
  const [currentFinalWeight, setCurrentFinalWeight] = useState(0);

  const searchInput = useRef(null);

  // Inicializamos el debounce para la búsqueda
  const debouncedSearchTerm = useDebounce(globalFilter, 2000);

  const toast = useRef(null);
  const dt = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const columns = JSON.stringify([
        {
          name: "id",
          type: "number",
        },
        {
          name: "createdDate",
          type: "date",
        },
        {
          name: "field_Name",
          type: "string",
        },
        {
          name: "crop_Name",
          type: "string",
        },
        {
          name: "truckPlate",
          type: "string",
        },
        {
          name: "destination_Name",
          type: "string",
        },
        {
          name: "purchaser_Name",
          type: "string",
        },
        {
          name: "weight",
          type: "number",
        },
        {
          name: "finalWeight",
          type: "number",
        },
      ]);

      const responseVouchersPaged = await api.get(
        `/vouchers/currentharvest/paged`,
        {
          params: {
            columns,
            page: first,
            pageSize: rows,
            sort: sortField ?? "CreatedDate",
            order: sortOrder >= 0 ? "DESC" : "ASC",
            globalFilter: debouncedSearchTerm,
            status: currentVoucherStatusFilter,
          },
        }
      );

      setTotalRecords(responseVouchersPaged.data.totalRows);
      setEntities(responseVouchersPaged.data.rows);
    } catch (error) {
      console.log(error);
      if (error.callback) {
        error.callback();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: t("errors.general"),
          life: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm, CRUDService]);

  useEffect(() => {
    fetchData();
  }, [
    first,
    rows,
    sortField,
    sortOrder,
    debouncedSearchTerm,
    currentVoucherStatusFilter,
  ]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await api.put(`/vouchers/updatefinalweight/${currentVoucherId}`, {
        id: currentVoucherId,
        finalWeight: data.finalWeight,
        clientId: localStorage.getItem("clientId"),
      });

      fetchData();

      hideFinalWeightDialog();

      toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const isNumber = (value) => {
    return typeof value === "number" && isFinite(value);
  };

  const openDetailDialog = async (selectedCrop) => {
    setDetailFormSettings({
      ...detailFormSettings,
      entityId: selectedCrop?.id,
    });
    setDetailDialog(true);
  };

  const openFormDialog = async (formMode, selectedCrop) => {
    setFormSettings({
      ...formSettings,
      formMode: formMode,
      entityId: selectedCrop?.id,
    });

    setFormDialog(true);
  };

  const hideFormDialog = (action) => {
    if (action !== "CANCEL") {
      setFormDialog(false);
      // Restablece todos los filtros a sus valores predeterminados
      setFirst(0);
      setRows(10);
      setSortField(null);
      setSortOrder(null);
      setGlobalFilter("");
      // Restablece el valor del campo de entrada
      if (searchInput.current) {
        searchInput.current.value = "";
      }
      // Luego, realiza una nueva búsqueda con los filtros restablecidos
      fetchData();
    }
    setFormDialog(false);
  };

  const openFinalWeightDialog = async (id, finalWeight) => {
    setCurrentVoucherId(id);
    setCurrentFinalWeight(finalWeight);
    setValue("finalWeight", finalWeight);
    setFinalWeightDialog(true);
  };

  const hideDetailDialog = (action) => {
    setDetailDialog(false);
  };

  const hideFinalWeightDialog = () => {
    setFinalWeightDialog(false);
  };

  const [formSettings, setFormSettings] = useState({
    entityId: -1,
    formMode: "",
    hideDialog: hideFormDialog,
    toast: toast,
    CRUDService: CRUDService,
  });

  const [detailFormSettings, setDetailFormSettings] = useState({
    entityId: -1,
    hideDialog: hideDetailDialog,
    toast: toast,
    CRUDService: CRUDService,
  });

  // const [finalWeightSettings, setFinalWeightSettings] = useState({
  //   entityId: -1,
  //   formMode: "",
  //   hideDialog: hideFinalWeightDialog,
  //   toast: toast,
  //   CRUDService: CRUDService,
  // });

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const rowClass = (data) => {
    return data.finalWeight ? "finalWeightSetted" : "";
  };

  const finalWeightInputNumber = (rowData) => {
    return (
      <div className="card flex justify-content-center">
        <Button
          label={`${rowData.finalWeight ?? "PENDIENTE"}`}
          link
          onClick={() =>
            openFinalWeightDialog(
              rowData.id,
              isNumber(rowData.finalWeight)
                ? rowData.finalWeight
                : rowData.weight
            )
          }
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openDetailDialog(rowData)}
        />
        {currentVoucherStatusFilter === "VOUCHERS_ACTIVE" && (
          <Button
            icon="pi pi-trash"
            className=" p-button-rounded p-button-danger"
            onClick={() => openFormDialog("DELETE", rowData)}
          />
        )}
      </React.Fragment>
    );
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  const header = (
    <div className="table-header">
      <div className="flex justify-content-start w-4">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            ref={searchInput}
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={`${t("DataTable.Search")}...`}
          />
        </span>
      </div>
      <div className="flex justify-content-center w-4">
        <SelectButton
          value={currentVoucherStatusFilter}
          onChange={(e) => setCurrentVoucherStatusFilter(e.value)}
          optionLabel="name"
          options={items}
        />
      </div>
      <div className="flex justify-content-end w-4">
        <Button
          label={t("DataTable.New")}
          icon="pi pi-plus"
          className="p-button-primary ml-3"
          onClick={() => openFormDialog("CREATE")}
        />
        <Button
          outlined
          label={t("DataTable.Export")}
          icon="pi pi-upload"
          className="p-button-secondary"
          onClick={exportCSV}
          style={{
            marginLeft: "20px",
          }}
        />
      </div>
    </div>
  );

  const getDialogTitle = () => {
    let action = "Add";

    if (formSettings.formMode == "DELETE") {
      action = "Cancelar";
    } else if (formSettings.formMode == "UPDATE") {
      action = "Edit";
    }

    return `${t(action)} ${t("entities.vouchers.singular")}`;
  };

  return (
    <BlockUI blocked={isLoading}>
      <Layout
        header={
          <Header
            icon={<i className="fa-solid fa-layer-group"></i>}
            title={`${t("entities.vouchers.pluralActives")} `}
          />
        }
      >
        <Toast ref={toast} />

        <div className={s.get("dataTableContainer")}>
          <DataTable
            onPage={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder);
            }}
            sortField={sortField}
            sortOrder={sortOrder}
            ref={dt}
            value={entities}
            lazy
            paginator
            first={first}
            rows={rows}
            rowClassName={rowClass}
            totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="fit"
            showGridlines
            dataKey="id"
            rowsPerPageOptions={[10, 50, 1000, 5000]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`${t(
              "DataTable.currentPageReportTemplate"
            )} ${t("Vouchers")}`}
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="id"
              header={t("entities.vouchers.number")}
              style={{ textAlign: "center" }}
              sortable
            ></Column>
            <Column
              field="createdDate"
              header={t("entities.vouchers.created_date")}
              body={DateTimeHelper.dateTemplateForList}
              style={{ textAlign: "center" }}
              sortable
            ></Column>
            <Column
              field="field_Name"
              header={t("entities.vouchers.field_Name")}
              sortable
            ></Column>
            <Column
              field="crop_Name"
              header={t("entities.vouchers.crop_Name")}
              sortable
            ></Column>
            <Column
              field="truckPlate"
              header={t("entities.vouchers.truckPlate")}
              sortable
            ></Column>
            <Column
              field="destination_Name"
              header={t("entities.vouchers.destination_Name")}
              sortable
            ></Column>
            <Column
              field="km"
              header={t("entities.vouchers.km")}
              style={{ textAlign: "right" }}
            ></Column>
            <Column
              field="purchaser_Name"
              header={t("entities.vouchers.purchaser_Name")}
              sortable
            ></Column>
            <Column
              field="weight"
              header={t("entities.vouchers.weight")}
              style={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              header={t("entities.vouchers.final_weight")}
              body={finalWeightInputNumber}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "8rem", textAlign: "center" }}
              className="action"
            ></Column>
          </DataTable>
        </div>

        <React.Fragment>
          <Dialog
            visible={formDialog}
            style={{ width: "600px" }}
            header={getDialogTitle}
            modal
            className="p-fluid"
            onHide={hideFormDialog}
          >
            <VoucherForm settings={formSettings} />
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
            visible={detailDialog}
            style={{ width: "90%" }}
            header={`${t("entities.vouchers.singular")}`}
            modal
            className="p-fluid"
            onHide={hideDetailDialog}
          >
            <VoucherDetail settings={detailFormSettings} />
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
            visible={finalWeightDialog}
            style={{ width: "20rem" }}
            header={`${t("entities.vouchers.singular")} ${currentVoucherId}`}
            modal
            className="p-fluid"
            onHide={() => {
              setFinalWeightDialog(false);
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <Controller
                  name="finalWeight"
                  control={control}
                  rules={{
                    required: t("forms.validation.validateMaxMin", {
                      entity: t("entities.vouchers.final_weight"),
                      min: 1,
                      max: 80000,
                    }),
                    validate: (value) =>
                      (value >= 1 && value <= 80000) ||
                      t("forms.validation.validateMaxMin", {
                        entity: t("entities.vouchers.final_weight"),
                        min: 1,
                        max: 80000,
                      }),
                  }}
                  render={({ field }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="finalWeight">
                          {t("entities.vouchers.final_weight")}
                        </label>
                        <div>{getFormErrorMessage(errors.finalWeight)}</div>
                      </div>
                      <div>
                        <InputNumber
                          suffix=" Kg"
                          locale="es-UY"
                          id={field.name}
                          value={currentFinalWeight}
                          onValueChange={(e) => field.onChange(e.value)}
                          mode="decimal"
                          showButtons
                          min={0}
                          max={100000}
                          inputClassName={[
                            classNames({ "p-invalid": errors.finalWeight }),
                          ]}
                          style={{ width: "17rem" }}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>

              <div className="flex justify-content-between">
                <Button
                  icon={isLoading ? "WAIT" : "pi pi-save"}
                  type="submit"
                  disabled={isLoading}
                />
              </div>
            </form>
          </Dialog>
        </React.Fragment>
      </Layout>
    </BlockUI>
  );
};

export default VouchersActive;
