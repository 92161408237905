import React, { useState, useEffect, useRef, useCallback } from "react";

import { BlockUI } from "primereact/blockui";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useTranslation } from "react-i18next";

import { useDebounce } from "../../../hooks/useDebounce";
import useCRUDService from "../../../hooks/useCRUDService";

import HarvestForm from "./harvestsForm";
import Layout from "../../../layout/layout";
import DateTimeHelper from "../../../utils/dateTimeHelper";
import Header from "../../../layout/header";

import useSkeleton from "../../../hooks/useSkeleton";

const Harvests = () => {
  const s = useSkeleton("commons");
  const { t } = useTranslation();
  const CRUDService = useCRUDService("harvests");

  const [entities, setEntities] = useState(null);
  const [formDialog, setFormDialog] = useState(false);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(-1);
  const [globalFilter, setGlobalFilter] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const searchInput = useRef(null);

  // Inicializamos el debounce para la búsqueda
  const debouncedSearchTerm = useDebounce(globalFilter, 2000);

  const toast = useRef(null);
  const dt = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const columns = JSON.stringify([
        {
          name: "name",
          type: "string",
        },
        {
          name: "createdDate",
          type: "date",
        },
        {
          name: "endDate",
          type: "date",
        },
      ]);

      const response = await CRUDService.getEntities(
        columns,
        first,
        rows,
        sortField ?? "createdDate",
        sortOrder >= 0 ? "ASC" : "DESC",
        debouncedSearchTerm
      );

      setTotalRecords(response.data.totalRows);
      setEntities(response.data.rows);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: t("errors.general"),
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm, CRUDService]);

  useEffect(() => {
    fetchData();
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm]);

  const openFormDialog = async (formMode, selectedHarvest) => {
    setFormSettings({
      ...formSettings,
      formMode: formMode,
      entityId: selectedHarvest?.id,
    });

    setFormDialog(true);
  };

  const hideDialog = (action) => {
    if (action !== "CANCEL") {
      setFormDialog(false);
      // Restablece todos los filtros a sus valores predeterminados
      setFirst(0);
      setRows(10);
      setSortField(null);
      setSortOrder(null);
      setGlobalFilter("");
      // Restablece el valor del campo de entrada
      if (searchInput.current) {
        searchInput.current.value = "";
      }
      // Luego, realiza una nueva búsqueda con los filtros restablecidos
      fetchData();
    }
    setFormDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const [formSettings, setFormSettings] = useState({
    harvestId: -1,
    formMode: "",
    hideDialog: hideDialog,
    toast: toast,
    CRUDService: CRUDService,
  });

  const actions = (
    <React.Fragment>
      <Button
        label={t("DataTable.New")}
        icon="pi pi-plus"
        className="p-button-primary ml-3"
        onClick={() => openFormDialog("CREATE")}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon={`pi ${
            DateTimeHelper.isValidDate(rowData.endDate)
              ? "pi-info-circle"
              : "pi-lock"
          }`}
          className="p-button-rounded p-button-warning"
          onClick={() => openFormDialog("CLOSE", rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          ref={searchInput}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={`${t("DataTable.Search")}...`}
        />
      </span>
      <Button
        outlined
        label={t("DataTable.Export")}
        icon="pi pi-upload"
        className="p-button-secondary"
        onClick={exportCSV}
        style={{
          marginLeft: "20px",
        }}
      />
    </div>
  );

  const getDialogTitle = () => {
    let action = "Add";

    if (formSettings.formMode == "DELETE") {
      action = "Delete";
    } else if (formSettings.formMode == "UPDATE") {
      action = "Edit";
    }

    return `${t(action)} ${t("entities.harvests.singular")}`;
  };

  return (
    <BlockUI blocked={isLoading}>
      <Layout
        header={
          <Header
            icon={<i className="fa-solid fa-calendar-week"></i>}
            title={t("entities.harvests.plural")}
            actions={actions}
          />
        }
      >
        <Toast ref={toast} />

        <div className={s.get("dataTableContainer")}>
          <DataTable
            onPage={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder);
            }}
            sortField={sortField}
            sortOrder={sortOrder}
            ref={dt}
            value={entities}
            lazy
            paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="fit"
            showGridlines
            dataKey="id"
            rowsPerPageOptions={[10, 50, 1000, 5000]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`${t(
              "DataTable.currentPageReportTemplate"
            )} ${t("Harvests")}`}
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="name"
              header={t("entities.harvests.name")}
              sortable
            ></Column>
            <Column
              field="createdDate"
              header={t("entities.harvests.created_date")}
              sortable
              body={DateTimeHelper.dateTemplateForList}
              style={{ textAlign: "center" }}
            ></Column>
            <Column
              field="endDate"
              header={t("entities.harvests.endDate")}
              sortable
              body={DateTimeHelper.dateTemplateForList}
            ></Column>
            <Column
              field="notes"
              header={t("entities.harvests.notes")}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "8rem", textAlign: "center" }}
              className="action"
            ></Column>
          </DataTable>

          <Dialog
            visible={formDialog}
            style={{ width: "600px" }}
            header={getDialogTitle()}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            <HarvestForm settings={formSettings} />
          </Dialog>
        </div>
      </Layout>
    </BlockUI>
  );
};

export default Harvests;
